<template>
  <div class="container">
  </div>
</template>

<script>

export default {
    name: "Program",
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
    },
    mounted() {
        window.location.replace('https://back.uib.kz/common/program.pdf')
    }
}
</script>